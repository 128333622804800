import { useDispatch, useSelector } from 'react-redux';
import authActions from '../../Redux/auth/actions';
import CustomPopover2 from '../Common/Popover/CustomPopover2';
import { ThreeDotIcon } from '../Icons';

const AdditionalExtrasPopover = ({
	additionalExtras,
	setFieldValue,
	name,
	values,
	posStatus
}) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	return (
		<CustomPopover2
			button={
				<ThreeDotIcon
					height={18}
					width={18}
					fill="#242424"
					className="cursor-pointer"
				/>
			}
			placement="bottom-end"
		>
			<div className="d-flex flex-column pa-12">
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						const copiedExtras = JSON.parse(
							JSON.stringify(additionalExtras)
						).map((eachValue) => {
							if (eachValue?.itemId) {
								delete eachValue.itemId;
							}
							return eachValue;
						});
						dispatch(
							authActions.copy_paste_additional_extras(
								copiedExtras
							)
						);
					}}
				>
					Copy
				</div>
				{!posStatus && (
					<div
						className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
						onClick={() => {
							if (authData?.copiedAdditionalExtras !== null) {
								const updatedExtras = [
									...JSON.parse(
										JSON.stringify(values.additionalExtras)
									),
									...JSON.parse(
										JSON.stringify(
											authData?.copiedAdditionalExtras
										)
									)
								];
								setFieldValue(name, updatedExtras);
							}
						}}
					>
						Paste Additional Extras
					</div>
				)}
				<div
					className="themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						if (authData?.copiedRequiredOptions !== null) {
							const updatedRequiredExtras = [
								...JSON.parse(
									JSON.stringify(values.additionalExtras)
								),
								...JSON.parse(
									JSON.stringify(
										authData?.copiedRequiredOptions
									)
								)
							];
							setFieldValue(name, updatedRequiredExtras);
						}
					}}
				>
					Paste Required Extra Items
				</div>
			</div>
		</CustomPopover2>
	);
};

export default AdditionalExtrasPopover;
